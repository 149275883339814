import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  Button,
  Box,
  Flex,
  Link,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { API_ROUTES } from '../../../services/routes';
import { ConfirmTradeAlert } from './ConfirmTrade';
import { DeclineTransactionModal } from './DeclineModal';
import { ViewImages } from './ViewImages';
import { usePagination } from '../../../utils/hooks/index.hooks';
import { GiftCard } from '../types';
import { CardDetails } from './CardDetails';

export function GiftCardSales({
  giftCardSales,
  refetch,
}: {
  giftCardSales: any[];
  refetch: () => any;
}) {
  const [images, setImages] = useState([]);
  const [openImagesModal, setOpenImagesModal] = useState(false);
  const [tradeId, setTradeId] = useState('');
  const [showApprovalAlert, setShowApprovalAlert] = useState(false);
  const [openDeclineModal, setOpenDeclineModal] = useState(false);
  const [action, setAction] = useState<'approve' | 'decline'>('approve');
  const { PaginationButtons, itemsToShow } = usePagination(giftCardSales);
  const [currentCard, setCurrentCard] = useState<GiftCard | undefined>();
  const [showCardDetails, setShowCardDetails] = useState(false);

  useEffect(() => {
    setShowCardDetails(!!currentCard);
  }, [currentCard]);

  useEffect(() => {
    if (images.length) {
      setOpenImagesModal(true);
    }
  }, [images]);

  useEffect(() => {
    if (tradeId) {
      if (action === 'approve') {
        setShowApprovalAlert(true);
      } else {
        setOpenDeclineModal(true);
      }
    }
  }, [tradeId, action]);

  return (
    <>
      <Box w='100%' px={[0, 0, 4, 8]} overflowX='auto'>
        <Table size='sm' variant='simple'>
          <TableCaption>Gift card sell requests</TableCaption>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Email</Th>
              <Th>Value</Th>
              <Th colSpan={2}>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {itemsToShow.map((card) => {
              const {
                value,
                _id,
                approved,
                declined,
                userId: { firstName, email },
              } = card;
              return (
                <Tr
                  _hover={{ cursor: 'grab', background: 'gray.200' }}
                  key={_id}
                  onClick={() => setCurrentCard(card)}
                >
                  <Td textTransform='capitalize'>{firstName}</Td>
                  <Td textTransform='lowercase'>
                    <Link href={`mailto:${email}`} isExternal>
                      {email}
                    </Link>
                  </Td>
                  <Td>
                    {value?.toLocaleString('en-NG', {
                      style: 'currency',
                      currency: 'NGN',
                    })}
                  </Td>
                  {approved ? <Td color='green'>Approved</Td> : null}
                  {declined ? <Td color='crimson'>Declined</Td> : null}
                  {!approved && !declined ? (
                    <>
                      <Td>
                        <Button
                          onClick={(e) => {
                            e.stopPropagation();
                            setAction('approve');
                            setTradeId(_id);
                          }}
                          disabled={approved}
                          colorScheme='blue'
                        >
                          Approve
                        </Button>
                      </Td>
                      <Td>
                        <Button
                          onClick={(e) => {
                            e.stopPropagation();
                            setAction('decline');
                            setTradeId(_id);
                          }}
                          disabled={approved}
                          colorScheme='red'
                        >
                          Decline
                        </Button>
                      </Td>
                    </>
                  ) : null}
                </Tr>
              );
            })}
          </Tbody>
          {!itemsToShow.length ? (
            <Tfoot>
              <Tr>
                <Td colSpan={3}>No gift card sales requested yet</Td>
              </Tr>
            </Tfoot>
          ) : null}
        </Table>
        <ViewImages
          isOpen={openImagesModal}
          onClose={() => {
            setOpenImagesModal(false);
            setImages([]);
          }}
          images={images}
        />
        <ConfirmTradeAlert
          isOpen={showApprovalAlert}
          onClose={() => {
            setShowApprovalAlert(false);
            setTradeId('');
            refetch();
          }}
          tradeId={tradeId}
          tradeUrl={API_ROUTES.APPROVE_GIFT_CARD_TRADE}
        />
        <DeclineTransactionModal
          isOpen={openDeclineModal}
          onClose={() => {
            setOpenDeclineModal(false);
            setTradeId('');
            refetch();
          }}
          tradeId={tradeId}
          tradeUrl={API_ROUTES.DECLINE_GIFT_CARD_TRADE}
        />
        <CardDetails
          open={showCardDetails}
          onClose={() => setCurrentCard(undefined)}
          card={currentCard}
        />
      </Box>
      <Flex mt={6} w='100%' justifyContent='center'>
        <PaginationButtons />
      </Flex>
    </>
  );
}
