import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  IconButton,
  Icon,
  InputGroup,
  Textarea,
  Box,
  Text,
  Flex,
  useToast,
  Heading,
  Divider,
} from '@chakra-ui/react';
import React, { useState, useRef, useEffect } from 'react';
import { IoAdd } from 'react-icons/io5';
import { useMutation } from 'react-query';
import { api } from '../../../services/api';
import { API_ROUTES } from '../../../services/routes';
import { useShowErrorNotification } from '../../../utils/hooks/index.hooks';
import { uploadImagesToCloudinary } from '../../../utils/index.helpers';

export function EditCardModal({ isOpen, onClose, card = { subcategories: [] }, }: {
  isOpen: boolean;
  onClose: () => any;
  card: any | null;
}) {


  const {
    category: initialCategory,
    terms: initialTerms,
    subcategories: initialSubCategories,
    _id,
  } = card;
  const [subcategories, setSubcategories] =
    useState<any[]>(initialSubCategories);
  const [category, setCategory] = useState(initialCategory);
  const [cardId, setCardId] = useState(_id);
  const [subCategory, setSubCategory] = useState('');
  const [subCategoryRate, setSubCategoryRate] = useState('');
  const [subCategoryMinimumAmount, setSubCategoryMinimumAmount] = useState('');
  const [file, setFile] = useState<any>(null);
  const [terms, setTerms] = useState(initialTerms);
  const { handleError } = useShowErrorNotification();
  const toast = useToast();
  const formRef = useRef(document.createElement('form'));
  //const [editing, setEditing] = useState(true)

  useEffect(() => {
    if (isOpen) {
      const {
        category: initialCategory,
        subcategories: initialSubCategories,
        _id: cardId,
      } = card;
      setCategory(initialCategory);
      setSubcategories(initialSubCategories);
      setCardId(cardId);
    }
  }, [card, isOpen]);

  const handleSubmit = async () => {
    try {
      if (file) {
        const [src] = await uploadImagesToCloudinary(file);
        return mutate(src);
      }
      mutate('');
    } catch (error) {
      handleError(error);
    }
  };

  const { mutate, isLoading } = useMutation(
    (image: any) =>
      api.patch(API_ROUTES.EDIT_GIFT_CARD, {
        ...(image && { image }),
        ...(subcategories.length && { subcategories }),
        ...(category && { category }),
        cardId,
      }),
    {
      onError(err: any) {
        handleError(err);
      },
      onSuccess({ data: { message } }) {
        toast({
          title: 'Successful.',
          status: 'success',
          duration: 6000,
          isClosable: true,
          position: 'top-right',
          description: message,
        });
        formRef.current.reset();
        onClose();
      },
    }
  );

  const addSubcategory = () => {
    if (!subCategory || !subCategoryRate || !terms) {
      return;
    }

    const newArray = [
      ...subcategories,
      {
        name: subCategory,
        rate: subCategoryRate,
        minimum_amount: subCategoryMinimumAmount,
        terms,
      },
    ];
    setSubcategories(newArray);
    setSubCategory('');
    setSubCategoryRate('');
    setTerms('');
    setSubCategoryMinimumAmount('');
  };

  const removeSubcategory = (index: number) => {
    const newArray = subcategories.filter((_, i) => i !== index);
    setSubcategories(newArray);
  };

  const handleEditSubCategoryChange = (key:string, index: number, e: any) => {
    let subcats = [...subcategories]
    subcats[index][key] = e.target.value
    setSubcategories(subcats)
    
  }

  return (
    <Modal
      closeOnEsc={false}
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Gift Card</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            id='edit-card'
            ref={formRef}
          >
            <FormControl mb={4}>
              <FormLabel>Category</FormLabel>
              <Input
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Subcategories</FormLabel>
              <InputGroup>
                <Input
                  placeholder='Enter subcategory'
                  value={subCategory}
                  onChange={(e) => setSubCategory(e.target.value)}
                  mr={2}
                />
                <Input
                  value={subCategoryRate}
                  onChange={(e) => setSubCategoryRate(e.target.value)}
                  type='number'
                  placeholder='Enter rate'
                  mr={2}
                />
                <Input
                  value={subCategoryMinimumAmount}
                  onChange={(e) => setSubCategoryMinimumAmount(e.target.value)}
                  type='number'
                  placeholder='Enter minimum amount'
                />
                <IconButton
                  ml={2}
                  aria-label='Add subcategory'
                  icon={<Icon as={IoAdd} />}
                  colorScheme='teal'
                  onClick={addSubcategory}
                />
              </InputGroup>
              <Textarea
                value={terms}
                onChange={(e) => setTerms(e.target.value)}
                placeholder='Enter subcategory terms'
                my={4}
              />
            </FormControl>
            {subcategories.length ? (
              <Box mt={4}>
                <Divider />
                <Heading mb={4} mt={2} fontSize='md'>
                  Selected categories
                </Heading>
                <Flex w='100%' flexWrap='wrap'>
                  {subcategories.length ? (
                    <Box mt={4}>
                      <Divider />
                      <Heading mb={4} mt={2} fontSize='md'>
                        Selected categories
                      </Heading>
                      <Flex w='100%' flexWrap='wrap'>
                        {subcategories.map(({ name, rate, terms }, i) => (
                          <Flex
                            w='100%'
                            justifyContent='space-between'
                            key={i}
                            flexWrap='wrap'
                            padding={2}
                            background='gray.200'
                            borderRadius={5}
                            mb={2}
                          >
                            <Input
                              flexBasis='30%'
                              value={name}
                              name={name}
                              onChange={(e) => handleEditSubCategoryChange("name",i,e)}
                              
                            />
                            {/* <Text flexBasis='30%' isTruncated>
                              {name}
                            </Text> */}

                            {/*  <Text flexBasis='15%'>
                              {Number(rate).toLocaleString('en-NG', {
                                style: 'currency',
                                currency: 'NGN',
                              })}
                            </Text> */}

                            <Input
                              flexBasis='30%'
                              
                              /* value= { Number(parseInt(rate)).toLocaleString('en-NG', {
                                style: 'currency',
                                currency: 'NGN',
                              })} */
                              type="number"
                              value={rate}
                              name={rate}
                              onChange={(e) => handleEditSubCategoryChange("rate",i,e)}
                              
                            />
                            
                            <Textarea flexBasis='40%'
                             whiteSpace='pre-wrap'
                             value = {terms}
                             name={terms}
                             onChange={(e) => handleEditSubCategoryChange("terms",i,e)}
                             />
                          
                            <Button
                              onClick={() => removeSubcategory(i)}
                              size='xs'
                              colorScheme='red'
                              my={2}
                            >
                              Remove
                            </Button>
                          </Flex>
                        ))}
                      </Flex>
                      <Divider my={4} />
                    </Box>
                  ) : null}
                </Flex>
                <Divider my={4} />
              </Box>
            ) : null}
            <FormControl mb={4}>
              <FormLabel>Image</FormLabel>
              <Input
                type='file'
                onChange={(e: any) => setFile(e.target.files)}
                accept='image/*'
                mb={2}
              />
            </FormControl>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button
            disabled={isLoading}
            colorScheme='blue'
            mr={3}
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            disabled={isLoading}
            isLoading={isLoading}
            type='submit'
            form='edit-card'
          >
            Edit card
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
