import {
  Box,
  Divider,
  Flex,
  Heading,
  Link,
  Table,
  TableCaption,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  Button,
} from '@chakra-ui/react';
import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { api } from '../../../services/api';
import { API_ROUTES } from '../../../services/routes';
import { PageLoading } from '../../../utils/components/loading/PageLoading';
import { PageHeading } from '../../../utils/components/PageHeading';
import {
  usePagination,
  useShowErrorNotification,
} from '../../../utils/hooks/index.hooks';
import { currencyFormatter } from '../../../utils/index.helpers';
import { Transaction, User as UserType } from '../types';

type Data = {
  title: string;
  subTitle: React.ReactNode;
};

const { useState } = React;

function DataGrid({ title, subTitle }: Data) {
  return (
    <Box
      minW={[null, null, 300]}
      borderRadius={8}
      p={4}
      mb={[4, 4, null]}
      bg='gray.200'
    >
      <Heading mb={2} as='h5' fontWeight='light' fontSize='medium'>
        {title}
      </Heading>
      <Box fontWeight='bold' fontSize='xl'>
        {subTitle}
      </Box>
    </Box>
  );
}

export function User() {
  const { userId } = useParams<any>();
  const [user, setUser] = useState<UserType | undefined>();
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [withdrawalValue, setWithdrawalValue] = useState(0);
  const [totalTrades, setTotalTrades] = useState(0);
  const [approvedTransactions, setApprovedTransactions] = useState(0);
  const [declinedTransactions, setDeclinedTransactions] = useState(0);
  const [pendingTransactions, setPendingTransactions] = useState(0);
  const [transactionValue, setTransactionValue] = useState(0);
  const { itemsToShow, PaginationButtons } = usePagination(transactions);
  const { handleError } = useShowErrorNotification();
  const { isLoading } = useQuery(
    'user query',
    () => api.get(API_ROUTES.getUser(userId)),
    {
      onError(e) {
        handleError(e);
      },
      onSuccess({ data }) {
        setUser(data.data.user);
        setTransactions(data.data.transactions);
        setTransactionValue(data.data.transactionValue);
        setWithdrawalValue(data.data.withdrawalValue);
        setTotalTrades(data.data.trades);
        setApprovedTransactions(data.data.approvedTransactions);
        setPendingTransactions(data.data.pendingTransactions);
        setDeclinedTransactions(data.data.declinedTransactions);
      },
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  if (isLoading) {
    return <PageLoading />;
  }

  return (
    <Box>
      <PageHeading heading='Customer Details' />
      <Flex
        justify={['center', 'center', 'space-between']}
        flexDir={['column', 'column', 'row']}
        flexWrap='wrap'
      >
        <DataGrid
          title='Customer Details'
          subTitle={
            <Flex
              flexWrap='wrap'
              flexDir={['column', 'column', 'row']}
              justify={['center', 'center', 'space-between']}
            >
              <DataGrid
                title='Name'
                subTitle={`${user?.firstName} ${user?.lastName}`}
              />
              <DataGrid
                title='Email'
                subTitle={
                  <Link isExternal href={`mailto:${user?.email}`}>
                    {user?.email}
                  </Link>
                }
              />
              <DataGrid
                title='Phone'
                subTitle={
                  <Link isExternal href={`tel:${user?.phone}`}>
                    {user?.phone}
                  </Link>
                }
              />
            </Flex>
          }
        />
      </Flex>
      <Flex
        justify={['center', 'center', 'space-between']}
        flexDir={['column', 'column', 'row']}
        flexWrap='wrap'
      >
        <DataGrid
          title='Registered'
          subTitle={new Date(
            user?.createdAt || Date.now()
          ).toLocaleDateString()}
        />
        <DataGrid
          title='Total Transactions'
          subTitle={`${transactions.length.toLocaleString('en-NG')}`}
        />
        <DataGrid
          title='Total Approved Transactions'
          subTitle={`${transactions
            .filter(({ status }) => status === 'approved')
            .length.toLocaleString('en-NG')}`}
        />
        <DataGrid
          title='Total Declined Transactions'
          subTitle={`${transactions
            .filter(({ status }) => status === 'declined')
            .length.toLocaleString('en-NG')}`}
        />
        <DataGrid
          title='Total Pending Transactions'
          subTitle={`${transactions
            .filter(({ status }) => status === 'pending')
            .length.toLocaleString('en-NG')}`}
        />
        <DataGrid
          title='Total Transactions Value'
          subTitle={currencyFormatter(transactionValue)}
        />
        <DataGrid
          title='Approved Transactions Value'
          subTitle={currencyFormatter(approvedTransactions)}
        />
        <DataGrid
          title='Pending Transactions Value'
          subTitle={currencyFormatter(pendingTransactions)}
        />
        <DataGrid
          title='Declined Transactions Value'
          subTitle={currencyFormatter(declinedTransactions)}
        />
        <DataGrid
          title='Total Trades'
          subTitle={currencyFormatter(totalTrades)}
        />
        <DataGrid
          title='Total Withdrawals'
          subTitle={currencyFormatter(withdrawalValue)}
        />
        <DataGrid
          title='Account Balance'
          subTitle={currencyFormatter(user?.balance || 0)}
        />
        <DataGrid
          title='Provisional Balance'
          subTitle={currencyFormatter(user?.provisionalBalance || 0)}
        />
      </Flex>
      <Divider mb={6} />
      <Box overflowX='auto'>
        <Table>
          <TableCaption placement='top'>Transactions</TableCaption>
          <Thead bg='gray.200'>
            <Tr>
              <Th>Date</Th>
              <Th>Type</Th>
              <Th>Activity</Th>
              <Th>Value</Th>
              <Th>Status</Th>
            </Tr>
          </Thead>
          <Tbody>
            {itemsToShow.map(
              ({
                _id,
                createdAt,
                type,
                narration,
                amount,
                status,
              }: Transaction) => (
                <Tr key={_id}>
                  <Td>{new Date(createdAt).toLocaleDateString()}</Td>
                  <Td>{type}</Td>
                  <Td>{narration}</Td>
                  <Td>{currencyFormatter(amount)}</Td>
                  <Td textTransform='capitalize'>{status}</Td>
                </Tr>
              )
            )}
          </Tbody>
        </Table>
      </Box>
      <Flex mt={4} justify='center'>
        <PaginationButtons />
      </Flex>
      <Divider />
      <Flex my={8}>
        <Button colorScheme='red'>Suspend user</Button>
        <Button colorScheme=''>Suspend user</Button>
      </Flex>
    </Box>
  );
}
