import * as React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  Button,
  InputGroup,
  Input,
  useToast,
} from '@chakra-ui/react';
import { API_ROUTES } from '../../services/routes';
import { useMutation } from 'react-query';
import { api } from '../../services/api';
import { ResetPassword } from './ResetPassword';
import { useShowErrorNotification } from '../../utils/hooks/index.hooks';

const { useState } = React;

type Props = {
  isOpen: boolean;
  onClose: () => any;
};

export function ForgotPassword({ isOpen, onClose }: Props) {
  const [email, setEmail] = useState('');
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const toast = useToast();
  const { handleError } = useShowErrorNotification();

  const { mutate, isLoading } = useMutation(
    () => api.post(API_ROUTES.REQUEST_PASSWORD_CHANGE, { email }),
    {
      onError(err: any) {
        handleError(err);
      },
      onSuccess({ data: { message } }) {
        toast({
          title: 'Verification code sent.',
          description: message,
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top-right',
        });
        setShowVerificationModal(true);
      },
    }
  );

  return (
    <Modal
      closeOnOverlayClick={false}
      closeOnEsc={false}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Password reset request</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              mutate();
            }}
            id='password-reset-request'
          >
            <FormControl>
              <InputGroup>
                <InputGroup>
                  <Input
                    onChange={(e) => setEmail(e.target.value)}
                    type='email'
                    placeholder='Enter your registered email'
                    variant='filled'
                    value={email}
                    required
                  />
                </InputGroup>
              </InputGroup>
            </FormControl>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme='blue' mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            form='password-reset-request'
            type='submit'
            disabled={!email || isLoading}
            variant='ghost'
            isLoading={isLoading}
          >
            Send verification code
          </Button>
          <ResetPassword
            isOpen={showVerificationModal}
            onClose={() => setShowVerificationModal(false)}
            email={email}
            retryFunction={() => mutate()}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
