import {
  Center,
  Input,
  InputGroup,
  InputRightElement,
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Heading,
  Flex,
} from '@chakra-ui/react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useShowErrorNotification } from '../../utils/hooks/index.hooks';
import { api } from '../../services/api';
import { API_ROUTES } from '../../services/routes';
import backgroundImage from '../../assets/images/cryptocurrency-concept-background.webp';
import { ForgotPassword } from './ForgotPassword';

export function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [openForgotPasswordModal, setOpenForgotPasswordModal] = useState(false);
  const [password, setPassword] = useState('');
  const { handleError } = useShowErrorNotification();
  const { mutate, isLoading } = useMutation(
    () =>
      api.post(API_ROUTES.ADMIN_LOGIN, {
        password,
        userId: email,
      }),
    {
      onError(err: any) {
        handleError(err);
      },
      onSuccess() {
        window.location.reload();
      },
    }
  );

  return (
    <Center bgImage={`url(${backgroundImage})`} h='100vh' w='100vw'>
      <ForgotPassword
        isOpen={openForgotPasswordModal}
        onClose={() => setOpenForgotPasswordModal(false)}
      />
      <Box borderRadius={10} p={6} bg='white'>
        <Heading fontWeight='regular' opacity={0.7} mb={8}>
          King cards admin login
        </Heading>
        <Box p={4} w={[300, 500]}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              mutate();
            }}
          >
            <FormControl mb={4}>
              <FormLabel>Enter email</FormLabel>
              <Input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type='email'
                isRequired
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Enter password</FormLabel>
              <InputGroup>
                <Input
                  autoComplete='off'
                  type={showPassword ? 'text' : 'password'}
                  minLength={8}
                  value={password}
                  isRequired
                  onChange={(e) => setPassword(e.target.value)}
                />
                <InputRightElement>
                  <IconButton
                    icon={showPassword ? <FaEyeSlash /> : <FaEye />}
                    aria-label='toggle password visibility'
                    onClick={() => setShowPassword((value) => !value)}
                  />
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <Flex flexWrap='wrap' justify='space-between'>
              <Button
                disabled={isLoading}
                isLoading={isLoading}
                colorScheme='blue'
                type='submit'
              >
                Login
              </Button>
              <Button
                onClick={() => setOpenForgotPasswordModal(true)}
                disabled={isLoading}
                isLoading={isLoading}
                variant='link'
              >
                Forgot Password
              </Button>
            </Flex>
          </form>
        </Box>
      </Box>
    </Center>
  );
}
