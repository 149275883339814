import {
  Box,
  Flex,
  Text,
  Image,
  Heading,
  Button,
  Divider,
  Input,
  Spinner,
} from '@chakra-ui/react';
import { useState, useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import InfiniteScroll from 'react-infinite-scroll-component';

import { api } from '../../services/api';
import { API_ROUTES } from '../../services/routes';
import { PageLoading } from '../../utils/components/loading/PageLoading';
import { useShowErrorNotification } from '../../utils/hooks/index.hooks';
import { EditCardModal } from './components/EditCard';
import { ConfirmDeleteCardAlert } from './components/ConfirmDeleteCard';
import { PageHeading } from '../../utils/components/PageHeading';

function Card({
  card,
  refetch,
  showEditModal,
}: {
  card: any;
  refetch: () => any;
  showEditModal: (card: any) => any;
}) {
  const { category, image, _id } = card;
  const [showDeleteAlert, setShowDeleteALert] = useState(false);

  return (
    <Flex
      borderWidth={3}
      borderColor='gray.400'
      borderRadius={10}
      flexDir='column'
      p={4}
      mb={6}
      mx={[0, 0, 6]}
      w={['300px', '300px', '300px', '350px']}
    >
      <Heading fontSize='lg' mb={1}>
        {category}
      </Heading>
      <Divider mb={4} />
      <Image loading='lazy' mb={4} src={image} alt={category} height={200} />
      <Flex>
        <Button
          onClick={() => showEditModal(card)}
          colorScheme='orange'
          mr={4}
          size='sm'
        >
          Edit
        </Button>
        <Button
          onClick={() => setShowDeleteALert(true)}
          size='sm'
          colorScheme='red'
        >
          Delete
        </Button>
      </Flex>
      <ConfirmDeleteCardAlert
        isOpen={showDeleteAlert}
        cardId={_id}
        onClose={() => {
          refetch();
          setShowDeleteALert(false);
        }}
      />
    </Flex>
  );
}

export function ManageCards() {
  const { handleError } = useShowErrorNotification();
  const [showEditModal, setShowEditModal] = useState(false);
  const [cards, setCards] = useState<any[]>([]);
  const [cardsToShow, setCardsToShow] = useState<any[]>([]);
  const [searchText, setSearchText] = useState('');
  const allCards = useRef<any[]>([]);
  const numItemsPerBatch = 15;
  const [card, setCard] = useState<any>();

  useEffect(() => {
    if (card) {
      setShowEditModal(true);
    }
  }, [card]);

  useEffect(() => {
    const newArray = cards.filter((_, index) => index < numItemsPerBatch);
    setCardsToShow(newArray);
  }, [cards]);

  useEffect(() => {
    if (!searchText) {
      setCardsToShow(allCards.current);
    } else {
      const matches = allCards.current.filter(({ category }: any) =>
        category.toLowerCase().includes(searchText.toLowerCase())
      );
      setCardsToShow(matches);
    }
  }, [searchText]);

  const next = () => {
    const newArray = cards.filter(
      (_, index) =>
        index >= cardsToShow.length &&
        index < cardsToShow.length + numItemsPerBatch
    );
    setCardsToShow((old) => [...old, ...newArray]);
  };

  const { isLoading, refetch } = useQuery(
    'manage cards query',
    () => api.get(API_ROUTES.FETCH_GIFT_CARDS),
    {
      onError(err: any) {
        handleError(err);
      },
      onSuccess({ data: { data } }) {
        setCards(data);
        allCards.current = data;
      },
      retry: false,
    }
  );

  if (isLoading) {
    return <PageLoading />;
  }

  return (
    <Box p={[0, 0, 4]}>
      <PageHeading heading='Card Management' />
      <Box px={[null, null, null, 4]} mb={6}>
        <Input
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          type='search'
          placeholder='Search gift card by category'
        />
        <EditCardModal
          isOpen={showEditModal}
          onClose={() => {
            refetch();
            setCard(undefined);
            setShowEditModal(false);
          }}
          card={card}
        />
      </Box>
      {cardsToShow.length ? (
        <InfiniteScroll
          height='90vh'
          next={next}
          hasMore={cardsToShow.length < cards.length}
          dataLength={cardsToShow.length}
          loader={<Spinner />}
        >
          <Flex
            justifyContent={['center', 'center', 'stretch']}
            flexWrap='wrap'
          >
            {cardsToShow.map((card) => (
              <Card
                showEditModal={(card) => setCard(card)}
                refetch={refetch}
                key={Math.random()}
                card={card}
              />
            ))}
          </Flex>
        </InfiniteScroll>
      ) : (
        <Text textAlign='center'>No cards created yet.</Text>
      )}
    </Box>
  );
}
