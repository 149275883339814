import { useState } from 'react';
import { Button, IconButton } from '@chakra-ui/button';
import {
  Flex,
  Text,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Icon,
} from '@chakra-ui/react';
import { useQuery } from 'react-query';
import { api } from '../../services/api';
import {
  useIsMobileDevice,
  useShowErrorNotification,
} from '../../utils/hooks/index.hooks';
import { LogoutAlert } from '../../pages/home/components/LogoutAlert';
import { AddGiftCardModal } from '../../pages/home/components/AddGiftCardModal';
import { AddBitcoinRateModal } from '../../pages/home/components/AddBitcoinRate';
import { API_ROUTES } from '../../services/routes';
import { FaHamburger } from 'react-icons/fa';
import { useHistory } from 'react-router';
import { AUTH_ROUTES } from '../../apps/auth/routes';

function MobileNavBar({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => any;
}) {
  return (
    <Drawer isOpen={isOpen} placement='right' onClose={onClose}>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Actions</DrawerHeader>
          <DrawerBody>
            <Flex
              justifyContent='flex-start'
              alignContent='flex-start'
              flexDir='column'
            >
              <NavButtons hideNavBar={onClose} />
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
}

export function StaffNavBar({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => any;
}) {
  const [showLogoutAlert, setShowLogoutAlert] = useState(false);
  return (
    <Drawer isOpen={isOpen} placement='left' onClose={onClose}>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Actions</DrawerHeader>
          <DrawerBody>
            <Flex
              justifyContent='flex-start'
              alignContent='flex-start'
              flexDir='column'
            >
              <Button
                colorScheme='red'
                variant='outline'
                onClick={() => setShowLogoutAlert(true)}
                my={6}
                mx={2}
              >
                Log out
              </Button>
              <LogoutAlert
                isOpen={showLogoutAlert}
                onClose={() => setShowLogoutAlert(false)}
              />
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
}

export function NavButtons({ hideNavBar }: { hideNavBar?: () => any }) {
  const [showLogoutAlert, setShowLogoutAlert] = useState(false);
  const [showGiftCardModal, setShowGiftCardModal] = useState(false);
  const [showBitcoinSetModal, setShowBitcoinSetModal] = useState(false);
  const history = useHistory();
  return (
    <>
      <Button
        onClick={() => history.push(AUTH_ROUTES.HOME)}
        size='lg'
        variant='link'
        py={6}
      >
        Home
      </Button>
      <Button
        onClick={() => {
          setShowBitcoinSetModal(true);
        }}
        my={4}
        mr={4}
        variant='link'
      >
        Set bitcoin rate
      </Button>
      <Button
        my={4}
        mr={4}
        onClick={() => {
          setShowGiftCardModal(true);
        }}
        variant='link'
      >
        Add gift card
      </Button>
      <Button
        onClick={() => {
          history.push(AUTH_ROUTES.USERS);
          hideNavBar && hideNavBar();
        }}
        my={4}
        mr={4}
        variant='link'
      >
        Manage users
      </Button>
      <Button
        onClick={() => {
          history.push(AUTH_ROUTES.CARDS);
          hideNavBar && hideNavBar();
        }}
        my={4}
        mr={4}
        variant='link'
      >
        Manage cards
      </Button>
      <Button
        onClick={() => {
          history.push(AUTH_ROUTES.STATS);
          hideNavBar && hideNavBar();
        }}
        my={4}
        mr={4}
        variant='link'
      >
        Stats
      </Button>
      <Button
        colorScheme='red'
        variant='outline'
        onClick={() => setShowLogoutAlert(true)}
        my={6}
        mx={2}
      >
        Log out
      </Button>
      <LogoutAlert
        isOpen={showLogoutAlert}
        onClose={() => setShowLogoutAlert(false)}
      />
      <AddGiftCardModal
        isOpen={showGiftCardModal}
        onClose={() => setShowGiftCardModal(false)}
      />
      <AddBitcoinRateModal
        isOpen={showBitcoinSetModal}
        onClose={() => setShowBitcoinSetModal(false)}
      />
    </>
  );
}

export function NavBar() {
  const [showMobileNavBar, setShowMobileNavBar] = useState(false);
  const [balance, setBalance] = useState(0);
  const { handleError } = useShowErrorNotification();
  const isMobile = useIsMobileDevice();
  useQuery('admin navbar request', () => api.get(API_ROUTES.ADMIN_DASHBOARD), {
    onError(err: any) {
      handleError(err);
    },
    onSuccess({ data }) {
      setBalance(data.data?.balance || 0);
    },
  });
  return (
    <Flex mb={4} p={[0, 0, 0, 4]} justifyContent='flex-end' flexWrap='wrap'>
      <Flex w='100%' mb={4}>
        <Text fontWeight='bold'>
          Wallet balance:{' '}
          {balance.toLocaleString('en-NG', {
            style: 'currency',
            currency: 'NGN',
          })}
        </Text>
      </Flex>
      {isMobile ? (
        <Flex>
          <IconButton
            aria-label='toggle navbar'
            icon={<Icon as={FaHamburger} />}
            onClick={() => setShowMobileNavBar((value) => !value)}
            colorScheme='blue'
          />
          <MobileNavBar
            isOpen={showMobileNavBar}
            onClose={() => setShowMobileNavBar(false)}
          />
        </Flex>
      ) : null}
    </Flex>
  );
}
