import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  Button,
  Box,
  Flex,
  Link,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { API_ROUTES } from '../../../services/routes';
import { ConfirmTradeAlert } from './ConfirmTrade';
import { DeclineTransactionModal } from './DeclineModal';
import { ViewImages } from './ViewImages';
import { usePagination } from '../../../utils/hooks/index.hooks';

export function BitcoinSales({
  bitcoinSales,
  refetch,
}: {
  bitcoinSales: any[];
  refetch: () => any;
}) {
  const [tradeId, setTradeId] = useState('');
  const [showApprovalAlert, setShowApprovalAlert] = useState(false);
  const [images, setImages] = useState([]);
  const [openImagesModal, setOpenImagesModal] = useState(false);
  const [openDeclineModal, setOpenDeclineModal] = useState(false);
  const [action, setAction] = useState<'approve' | 'decline'>('approve');
  const { PaginationButtons, itemsToShow } = usePagination(bitcoinSales);

  useEffect(() => {
    if (images.length) {
      setOpenImagesModal(true);
    }
  }, [images]);

  useEffect(() => {
    if (tradeId) {
      if (action === 'approve') {
        setShowApprovalAlert(true);
      } else {
        setOpenDeclineModal(true);
      }
    }
  }, [tradeId, action]);

  return (
    <>
      <Box w='100%' px={[0, 0, 4, 8]} overflowX='auto'>
        <Table size='sm' variant='simple'>
          <TableCaption>Bitcoin sell requests</TableCaption>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Email</Th>
              <Th>Purchase amount</Th>
              <Th>Rate</Th>
              <Th>Value</Th>
              <Th colSpan={3}>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {itemsToShow.map(
              (
                {
                  value,
                  _id,
                  approved,
                  purchaseAmount,
                  rate,
                  images: proofs,
                  declined,
                  userId: { email, firstName },
                },
                index
              ) => (
                <Tr key={_id}>
                  <Td textTransform='capitalize'>{firstName}</Td>
                  <Td textTransform='lowercase'>
                    <Link isExternal href={`mailto:${email}`}>
                      {email}
                    </Link>
                  </Td>
                  <Td>
                    {purchaseAmount?.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'BTC',
                      maximumFractionDigits: 15,
                    })}
                  </Td>
                  <Td>
                    {rate?.toLocaleString('en-NG', {
                      style: 'currency',
                      currency: 'NGN',
                    })}
                  </Td>
                  <Td>
                    {value?.toLocaleString('en-NG', {
                      style: 'currency',
                      currency: 'NGN',
                    })}
                  </Td>
                  {approved ? <Td color='green'>Approved</Td> : null}
                  {declined ? <Td color='crimson'>Declined</Td> : null}
                  {!approved && !declined ? (
                    <>
                      <Td>
                        <Button
                          onClick={() => {
                            setAction('approve');
                            setTradeId(_id);
                          }}
                          disabled={approved}
                          colorScheme='blue'
                        >
                          Approve
                        </Button>
                      </Td>
                      <Td>
                        <Button
                          onClick={() => {
                            setAction('decline');
                            setTradeId(_id);
                          }}
                          disabled={approved}
                          colorScheme='red'
                        >
                          Decline
                        </Button>
                      </Td>
                    </>
                  ) : null}
                </Tr>
              )
            )}
          </Tbody>
          {!itemsToShow.length ? (
            <Tfoot>
              <Tr>
                <Td colSpan={3}>No bitcoin sales requested yet</Td>
              </Tr>
            </Tfoot>
          ) : null}
        </Table>
        <ConfirmTradeAlert
          isOpen={showApprovalAlert}
          onClose={() => {
            setShowApprovalAlert(false);
            setTradeId('');
            refetch();
          }}
          tradeId={tradeId}
          tradeUrl={API_ROUTES.APPROVE_BITCOIN_TRADE}
        />
        <ViewImages
          images={images}
          isOpen={openImagesModal}
          onClose={() => {
            setOpenImagesModal(false);
            setImages([]);
          }}
        />
        <DeclineTransactionModal
          isOpen={openDeclineModal}
          onClose={() => {
            setOpenDeclineModal(false);
            setTradeId('');
            refetch();
          }}
          tradeId={tradeId}
          tradeUrl={API_ROUTES.DECLINE_BITCOIN_TRADE}
        />
      </Box>
      <Flex mt={6} w='100%' justifyContent='center'>
        <PaginationButtons />
      </Flex>
    </>
  );
}
