import axios from 'axios';
import { BASE_URL, APP_ID_KEY } from '../utils/index.constants';

export const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/json',
  },
});

const addTokenToRequest = (request: any) => {
  request.headers['x-id-key'] = localStorage.getItem(APP_ID_KEY);
  return request;
};

const getTokenFromRequest = (response: any) => {
  if (response.status !== 401) {
    const newToken = response.headers['x-id-key'];
    if (newToken) {
      localStorage.setItem(APP_ID_KEY, newToken);
    }
  }
  return response;
};

api.interceptors.response.use(getTokenFromRequest);

api.interceptors.request.use(addTokenToRequest);
