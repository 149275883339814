import * as React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  Button,
  HStack,
  PinInput,
  PinInputField,
  InputGroup,
  InputRightElement,
  IconButton,
  Input,
  useToast,
  FormLabel,
} from '@chakra-ui/react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { api } from '../../services/api';
import { useMutation } from 'react-query';
import { API_ROUTES } from '../../services/routes';
import { useShowErrorNotification } from '../../utils/hooks/index.hooks';

const { useState } = React;

type Props = {
  isOpen: boolean;
  onClose: () => any;
  email: string;
  retryFunction: () => any;
};

export function ResetPassword({
  isOpen,
  onClose,
  email,
  retryFunction,
}: Props) {
  const [verificationCode, setVerificationCode] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const handleChange = (code: string) => setVerificationCode(code);

  const toast = useToast();
  const { handleError } = useShowErrorNotification();

  const { mutate, isLoading } = useMutation(
    () =>
      api.patch(API_ROUTES.RESET_PASSWORD, {
        password,
        verificationCode,
        email,
      }),
    {
      onError(err: any) {
        handleError(err);
      },
      onSuccess({ data: { message } }) {
        toast({
          title: 'Password reset',
          description: message,
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top-right',
        });
        onClose();
      },
    }
  );

  return (
    <Modal
      closeOnOverlayClick={false}
      closeOnEsc={false}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Reset password</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              mutate();
            }}
            id='password-reset'
          >
            <FormControl mb={4}>
              <FormLabel>Enter token</FormLabel>
              <HStack>
                <PinInput onChange={handleChange} type='number' otp>
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                </PinInput>
              </HStack>
            </FormControl>
            <FormControl>
              <InputGroup>
                <InputGroup>
                  <Input
                    onChange={(e) => setPassword(e.target.value)}
                    type={showPassword ? 'text' : 'password'}
                    placeholder='Enter your new password'
                    autoComplete='off'
                    variant='filled'
                    minLength={8}
                    value={password}
                  />
                  <InputRightElement>
                    <IconButton
                      aria-label='Toggle show password'
                      icon={showPassword ? <FaEyeSlash /> : <FaEye />}
                      variant='ghost'
                      onClick={() => setShowPassword((value) => !value)}
                    />
                  </InputRightElement>
                </InputGroup>
              </InputGroup>
            </FormControl>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button
            disabled={isLoading}
            colorScheme='blue'
            mr={3}
            onClick={retryFunction}
          >
            Resend code
          </Button>
          <Button
            form='password-reset'
            disabled={verificationCode.length < 5 || isLoading}
            variant='ghost'
            isLoading={isLoading}
            type='submit'
          >
            Reset password
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
