import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  useToast,
} from '@chakra-ui/react';
import { useRef } from 'react';
import { api } from '../../../services/api';
import { useShowErrorNotification } from '../../../utils/hooks/index.hooks';
import { useMutation } from 'react-query';
import { API_ROUTES } from '../../../services/routes';

type Props = {
  cardId: string;
  isOpen: boolean;
  onClose: () => any;
};

export function ConfirmDeleteCardAlert({ cardId, isOpen, onClose }: Props) {
  const cancelRef = useRef<any>();
  const toast = useToast();
  const { handleError } = useShowErrorNotification();

  const { mutate, isLoading } = useMutation(
    () => api.delete(`${API_ROUTES.DELETE_GIFT_CARD}/${cardId}`),
    {
      onError(err: any) {
        handleError(err);
      },
      onSuccess({ data: { message } }) {
        toast({
          title: 'Successful.',
          status: 'success',
          duration: 6000,
          isClosable: true,
          position: 'top-right',
          description: message,
        });
        onClose();
      },
    }
  );

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            Delete Card
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure? You can't undo this action afterwards.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button
              disabled={isLoading}
              isLoading={isLoading}
              colorScheme='red'
              onClick={() => mutate()}
              ml={3}
            >
              Delete it
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
