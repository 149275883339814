/* eslint-disable no-await-in-loop */

import imageCompression from 'browser-image-compression';

/**
 * Compresses an image
 * @param imgFile the image file to be compressed
 */
export function compressImage(imgFile: File): Promise<File | Blob> {
  // you should provide one of maxSizeMB, maxWidthOrHeight in the options
  const options = {
    maxSizeMB: 0.1,
  };

  return imageCompression(imgFile, options);
}

export async function uploadImagesToCloudinary(files: FileList) {
  try {
    const fileArray = Object.keys(files);
    const srcs = [];
    for (let imageIndex = 0; imageIndex < fileArray.length; imageIndex++) {
      const compressedImage = await compressImage(files[imageIndex]);
      const form = new FormData();
      form.append('file', compressedImage);
      form.append('upload_preset', 'king-cards-unsigned');
      const response = await fetch(
        'https://api.cloudinary.com/v1_1/king-cards/upload',
        {
          method: 'POST',
          body: form,
        }
      );
      const res = await response.json();
      srcs.push(res.secure_url);
    }
    return srcs;
  } catch (error: any) {
    throw new Error(error);
  }
}

/**
 * Formats amounts in the desired local currency
 * @param amount the amount to be formatted
 * @param currency the local currency
 * @returns string
 */
export function currencyFormatter(amount: number, currency = 'NGN') {
  return amount.toLocaleString('en-NG', {
    style: 'currency',
    currency,
    maximumFractionDigits: 2,
  });
}
