import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  Button,
  Box,
  Flex,
  Link,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { API_ROUTES } from '../../../services/routes';
import { ConfirmTradeAlert } from './ConfirmTrade';
import { DeclineTransactionModal } from './DeclineModal';
import { usePagination } from '../../../utils/hooks/index.hooks';

export function WithdrawalTables({
  withdrawals,
  refetch,
}: {
  withdrawals: any[];
  refetch: () => any;
}) {
  const [tradeId, setTradeId] = useState('');
  const [showConfirmAlert, setShowConfirmAlert] = useState(false);
  const [openDeclineModal, setOpenDeclineModal] = useState(false);
  const [action, setAction] = useState<'approve' | 'decline'>('approve');
  const { PaginationButtons, itemsToShow} = usePagination(withdrawals);

  useEffect(() => {
    if (tradeId) {
      if (action === 'approve') {
        setShowConfirmAlert(true);
      } else {
        setOpenDeclineModal(true);
      }
    }
  }, [tradeId, action]);

  return (
    <>
      <Box w='100%' px={[0, 0, 4, 8]} overflowX='auto'>
        <Table size='sm' variant='simple'>
          <TableCaption>Withdrawals</TableCaption>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Email</Th>
              <Th>Amount</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {itemsToShow.map(
              ({
                value,
                _id,
                approved,
                declined,
                userId: { email, firstName },
              }) => (
                <Tr key={_id}>
                  <Td textTransform='capitalize'>{firstName}</Td>
                  <Td textTransform='lowercase'>
                    <Link isExternal href={`mailto:${email}`}>
                      {email}
                    </Link>
                  </Td>
                  <Td>
                    {value.toLocaleString('en-NG', {
                      style: 'currency',
                      currency: 'NGN',
                    })}
                  </Td>
                  {approved ? <Td color='green'>Approved</Td> : null}
                  {declined ? <Td color='crimson'>Declined</Td> : null}
                  {!approved && !declined ? (
                    <>
                      <Td>
                        <Button
                          onClick={() => {
                            setAction('approve');
                            setTradeId(_id);
                          }}
                          colorScheme='blue'
                        >
                          Approve
                        </Button>
                      </Td>
                      <Td>
                        <Button
                          onClick={() => {
                            setAction('decline');
                            setTradeId(_id);
                          }}
                          colorScheme='red'
                        >
                          Decline
                        </Button>
                      </Td>
                    </>
                  ) : null}
                </Tr>
              )
            )}
          </Tbody>
          {!itemsToShow.length ? (
            <Tfoot>
              <Tr>
                <Td colSpan={3}>No withdrawals requested yet</Td>
              </Tr>
            </Tfoot>
          ) : null}
        </Table>
        <ConfirmTradeAlert
          isOpen={showConfirmAlert}
          tradeId={tradeId}
          onClose={() => {
            setTradeId('');
            refetch();
            setShowConfirmAlert(false);
          }}
          tradeUrl={API_ROUTES.APPROVE_WITHDRAWAL}
        />
        <DeclineTransactionModal
          isOpen={openDeclineModal}
          onClose={() => {
            setOpenDeclineModal(false);
            setTradeId('');
            refetch();
          }}
          tradeId={tradeId}
          tradeUrl={API_ROUTES.DECLINE_WITHDRAWAL}
        />
      </Box>
      <Flex mt={6} w='100%' justifyContent='center'>
        <PaginationButtons />
      </Flex>
    </>
  );
}
