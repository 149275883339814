import { useState } from 'react';
import { Box, Divider, Flex } from '@chakra-ui/react';
import { BitcoinSales } from './components/BitcoinSales';
import { GiftCardSales } from './components/GiftCardSales';
import { WithdrawalTables } from './components/WithdrawalTable';
import { useQuery } from 'react-query';
import { api } from '../../services/api';
import { useShowErrorNotification } from '../../utils/hooks/index.hooks';
import { PageLoading } from '../../utils/components/loading/PageLoading';
import { API_ROUTES } from '../../services/routes';
import { PageHeading } from '../../utils/components/PageHeading';

export function Dashboard() {
  const { handleError } = useShowErrorNotification();
  const [bitcoin, setBitcoin] = useState<any[]>([]);
  const [withdrawals, setWithdrawals] = useState<any[]>([]);
  const [cards, setCards] = useState<any[]>([]);
  const { isLoading, refetch } = useQuery(
    'admin dashboard request',
    () => api.get(API_ROUTES.ADMIN_DASHBOARD),
    {
      onError(err: any) {
        handleError(err);
      },
      onSuccess({ data: { data } }) {
        setBitcoin(data?.bitcoins || []);
        setCards(data?.cards || []);
        setWithdrawals(data?.withdrawals || []);
      },
    }
  );

  if (isLoading) {
    return <PageLoading />;
  }
  return (
    <Box>
      <PageHeading heading='Transaction Management' />
      <Flex justifyContent='space-between' w='100%' flexWrap='wrap'>
        <WithdrawalTables refetch={refetch} withdrawals={withdrawals} />
        <Divider mb={32} />
        <BitcoinSales refetch={refetch} bitcoinSales={bitcoin} />
        <Divider mb={32} />
        <GiftCardSales refetch={refetch} giftCardSales={cards} />
      </Flex>
    </Box>
  );
}
