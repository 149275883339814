import {useEffect, useState } from 'react';
import { Admin } from './apps/auth';
import { Public } from './apps/public';
import { PageLoading } from './utils/components/loading/PageLoading';
import { APP_ID_KEY } from './utils/index.constants';

function App() {
  const [loading, setLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const userId = localStorage.getItem(APP_ID_KEY);
    if (userId) {
      setIsLoggedIn(true);
    }
    setLoading(false);
  }, []);

  if (loading) {
    return <PageLoading />
  }

  if (isLoggedIn) {
    return <Admin />
  }

  return <Public />;
}

export default App;
