import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Textarea,
  Input,
} from '@chakra-ui/react';
import { useState, useRef } from 'react';

import { DeclineTradeAlert } from './DeclineTransactionAlert';

export function DeclineTransactionModal({
  isOpen,
  onClose,
  tradeId,
  tradeUrl,
}: {
  isOpen: boolean;
  onClose: () => any;
  tradeId: string;
  tradeUrl: string;
}) {
  const [reason, setreason] = useState('');
  const [showDeclineAlert, setShowDeclineAlert] = useState(false);
  const [files, setFiles] = useState<FileList | null>(null);
  const formRef = useRef(document.createElement('form'));

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Decline transaction</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setShowDeclineAlert(true);
            }}
            id='decline-transaction'
            ref={formRef}
          >
            <FormControl mb={4}>
              <FormLabel>Select images</FormLabel>
              <Input
                type='file'
                onChange={(e) => setFiles(e.target.files)}
                multiple
                accept='image/*'
              />
            </FormControl>
            <FormControl mb={4} isRequired>
              <FormLabel>Reason</FormLabel>
              <Textarea
                value={reason}
                onChange={(e) => setreason(e.target.value)}
                rows={5}
              />
            </FormControl>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme='blue' mr={3} onClick={onClose}>
            Close
          </Button>
          <Button type='submit' form='decline-transaction'>
            Confirm
          </Button>
          <DeclineTradeAlert
            tradeId={tradeId}
            isOpen={showDeclineAlert}
            onClose={() => {
              setShowDeclineAlert(false);
              formRef.current.reset();
              setreason('');
              setFiles(null);
              onClose();
            }}
            tradeUrl={tradeUrl}
            reason={reason}
            images={files}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
