export const API_ROUTES = {
  HOME: '/',
  LOGIN: '/user/login',
  RESET_PASSWORD: '/user/password/reset',
  CHANGE_PASSWORD: '/user/password/change',
  REQUEST_PASSWORD_CHANGE: '/user/password/request',
  ADMIN_LOGIN: '/admin/login',
  ADMIN_DASHBOARD: '/admin/dashboard',
  APPROVE_GIFT_CARD_TRADE: '/admin/card/approve',
  APPROVE_BITCOIN_TRADE: '/admin/bitcoin/approve',
  CREATE_GIFT_CARD: '/user/gift-card/create',
  SET_RATE: '/user/rate',
  APPROVE_WITHDRAWAL: '/admin/withdrawal/approve',
  DECLINE_GIFT_CARD_TRADE: '/admin/card/decline',
  DECLINE_BITCOIN_TRADE: '/admin/bitcoin/decline',
  DECLINE_WITHDRAWAL: '/admin/withdrawal/decline',
  FETCH_GIFT_CARDS: '/user/gift-cards',
  DELETE_GIFT_CARD: '/admin/giftcard',
  EDIT_GIFT_CARD: '/admin/giftcard/edit',
  GET_USERS: '/admin/users',
  getUser: (userId: string) => `/admin/user/${userId}`,
  GET_STATS: '/admin/statistics',
  ADMIN_ME: '/admin/me',
  STAFF_DASHBOARD: '/staff/dashboard',
};
