import {
  useToast,
  useMediaQuery,
  Text,
  Flex,
  Icon,
  IconButton,
  Select,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from 'react-icons/ai';

export function useShowErrorNotification() {
  const toast = useToast();
  const showToast = (error: any) => {
    const message =
      typeof error === 'string'
        ? error
        : error.response?.status === 401
        ? 'Please log in again to continue'
        : error.response?.data.message ||
          'Something went wrong. Please try again';
    const heading =
      error.response?.status === 401 ? 'Session expired.' : 'Error occured';
    toast({
      title: heading,
      description: message,
      status: 'error',
      isClosable: true,
    });
  };
  return { handleError: showToast };
}

export function useIsMobileDevice() {
  const [isMobile] = useMediaQuery('(max-width: 600px)');
  return isMobile;
}

/**
 * Helps us manage pagination for data that is too long to fit into one screen
 * @param filterResults the filtered results if any filter is applied like a search button
 * else you can just pass it your data and it will paginate it for you
 * @returns an object containing a function to render the pagination buttons
 * and the items for you to show
 */
export function usePagination(filterResults: any[]) {
  const [numTableItemsToShow, setNumTableItemsToShow] = useState(10);
  const [numPages, setNumPages] = useState(1);
  const [paginationPage, setPaginationPage] = useState(1);
  const [itemsToShow, setItemsToShow] = useState<any[]>([]);

  const next = () => setPaginationPage((page) => page + 1);

  const previous = () => setPaginationPage((page) => page - 1);

  useEffect(() => {
    const firstItemIndex = (paginationPage - 1) * numTableItemsToShow;
    const lastItemIndex = firstItemIndex + numTableItemsToShow;
    const numOfPages = Math.ceil(filterResults.length / numTableItemsToShow);
    setNumPages(numOfPages);
    setItemsToShow([...filterResults].slice(firstItemIndex, lastItemIndex));
  }, [numTableItemsToShow, filterResults, paginationPage]);

  const PaginationButtons = () => (
    <Flex flexWrap='wrap' p={2}>
      <Text
        mr={['auto', 'auto', '4rem']}
        mb={4}
        color='#637381'
        fontSize='small'
      >
        Showing {paginationPage} of {numPages || 1}
      </Text>
      <Flex>
        <IconButton
          aria-label='go to previous page'
          mr={4}
          size='xs'
          icon={<Icon as={AiOutlineDoubleLeft} />}
          disabled={paginationPage <= 1}
          onClick={previous}
        />
        <Text mr={4} color='#637381' fontSize='small'>
          {paginationPage}
        </Text>
        <IconButton
          aria-label='go to next page'
          mr={4}
          size='xs'
          icon={<Icon as={AiOutlineDoubleRight} />}
          disabled={paginationPage === numPages || !numPages}
          onClick={next}
        />
        <Select
          onChange={(e) => setNumTableItemsToShow(Number(e.target.value))}
          size='xs'
          value={numTableItemsToShow}
        >
          <option value='10'>10</option>
          <option value='20'>20</option>
          <option value='50'>50</option>
        </Select>
      </Flex>
    </Flex>
  );
  return { PaginationButtons, itemsToShow };
}