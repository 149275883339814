import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useToast,
  FormHelperText,
} from '@chakra-ui/react';
import { useState, useRef } from 'react';

import { useMutation } from 'react-query';
import { api } from '../../../services/api';
import { API_ROUTES } from '../../../services/routes';
import { useShowErrorNotification } from '../../../utils/hooks/index.hooks';

export function AddBitcoinRateModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => any;
}) {
  const [rate, setrate] = useState('');
  const [terms, setTerms] = useState('');
  const { handleError } = useShowErrorNotification();
  const toast = useToast();
  const formRef = useRef(document.createElement('form'));

  const { mutate, isLoading } = useMutation(
    () =>
      api.post(API_ROUTES.SET_RATE, {
        terms,
        rate,
      }),
    {
      onError(err: any) {
        handleError(err);
      },
      onSuccess({ data: { message } }) {
        toast({
          title: 'Successful.',
          status: 'success',
          duration: 6000,
          isClosable: true,
          position: 'top-right',
          description: message,
        });
        formRef.current.reset();
        setTerms('');
        setrate('');
      },
    }
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Set bitcoin rate</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              mutate();
            }}
            id='set-rate'
            ref={formRef}
          >
            <FormControl mb={4} isRequired>
              <FormLabel>Rate</FormLabel>
              <Input
                value={rate}
                onChange={(e) => setrate(e.target.value)}
                type='number'
              />
              {rate ? (
                <FormHelperText>
                  rate:{' '}
                  {Number(rate).toLocaleString('en-NG', {
                    style: 'currency',
                    currency: 'NGN',
                  })}
                </FormHelperText>
              ) : null}
            </FormControl>
            <FormControl mb={4} isRequired>
              <FormLabel>Terms</FormLabel>
              <Textarea
                value={terms}
                onChange={(e) => setTerms(e.target.value)}
              />
            </FormControl>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button
            disabled={isLoading}
            colorScheme='blue'
            mr={3}
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            disabled={isLoading}
            isLoading={isLoading}
            type='submit'
            form='set-rate'
          >
            Set rate
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
