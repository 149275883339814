import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';
import { useRef } from 'react';
import { APP_ID_KEY } from '../../../utils/index.constants';

type Props = {
  isOpen: boolean;
  onClose: () => any;
};

export function LogoutAlert({
  isOpen,
  onClose,
}: Props) {
  const cancelRef = useRef<any>();

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            Logging out
          </AlertDialogHeader>

          <AlertDialogBody>
            You will be logged out of the application
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme='red'
              onClick={() => {
                localStorage.removeItem(APP_ID_KEY);
                window.location.reload();
              }}
              ml={3}
            >
              OK. Sure
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
