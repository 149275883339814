import {
  Box,
  Table,
  TableCaption,
  Thead,
  Tr,
  Tbody,
  Th,
  Td,
  Flex,
  Link,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { api } from '../../services/api';
import { API_ROUTES } from '../../services/routes';
import { PageLoading } from '../../utils/components/loading/PageLoading';
import { PageHeading } from '../../utils/components/PageHeading';
import {
  usePagination,
  useShowErrorNotification,
} from '../../utils/hooks/index.hooks';
import { User } from './types';

export function Users() {
  const [users, setUsers] = useState<User[]>([]);
  const headings = ['name', 'joined', 'email', 'phone'];
  const history = useHistory();
  const { itemsToShow, PaginationButtons } = usePagination(users);
  const { handleError } = useShowErrorNotification();

  const { isLoading } = useQuery(
    'get users query',
    () => api.get(API_ROUTES.GET_USERS),
    {
      onError(e) {
        handleError(e);
      },
      onSuccess({ data }) {
        setUsers(data.data);
      },
      refetchOnWindowFocus: false,
      retry: false
    }
  );

  if (isLoading) {
    return <PageLoading />
  }

  return (
    <Box>
      <PageHeading heading='User Management' />
      <Box overflowX='auto'>
        <Table>
          <TableCaption placement='top'>Users</TableCaption>
          <Thead>
            <Tr>
              {headings.map((heading) => (
                <Th key={Math.random()}>{heading}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {itemsToShow.map(
              ({ _id, firstName, lastName, createdAt, email, phone }: User) => (
                <Tr
                  onClick={() => history.push(`/user/${_id}`)}
                  key={_id}
                  _hover={{ background: 'gray.200', cursor: 'grab' }}
                >
                  <Td>
                    {firstName} {lastName}
                  </Td>
                  <Td>{new Date(createdAt).toLocaleDateString()}</Td>
                  <Td>
                    <Link href={`mailto:${email}`}>{email}</Link>
                  </Td>
                  <Td>
                    <Link href={`tel:${phone}`}>{phone}</Link>
                  </Td>
                </Tr>
              )
            )}
          </Tbody>
        </Table>
      </Box>
      <Flex my={4} justify='center'>
        <PaginationButtons />
      </Flex>
    </Box>
  );
}
