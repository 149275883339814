import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { ROUTES } from './routes';
import { Login } from '../../pages/login/index';

export function Public() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path={ROUTES.LOGIN} exact component={Login} />
        <Redirect path='/*' to={ROUTES.LOGIN} />
      </Switch>
    </BrowserRouter>
  );
}
