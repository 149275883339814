import { Center, Spinner } from '@chakra-ui/react';

type Props = {
  height: string | number;
  width: string | number;
};

export function PageLoading() {
  return (
    <Center h='100vh' w='100vw'>
      <Spinner size='md' />
    </Center>
  );
}

export function LoadingResources({height, width}: Props) {
  return (
    <Center h={height} w={width}>
      <Spinner size='md' />
    </Center>
  );
}
