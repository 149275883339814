import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Flex,
  Box,
  Heading,
  Text,
  Link,
  Divider,
  Image,
} from '@chakra-ui/react';
import { currencyFormatter } from '../../../utils/index.helpers';
import { GiftCard } from '../types';

type Props = {
  card?: GiftCard;
  open: boolean;
  onClose: () => any;
};

export function CardDetails({ card, open, onClose }: Props) {
  return (
    <Modal isOpen={open} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Card Information</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <Heading fontSize='large'>Customer Information</Heading>
            <Box mt={4}>
              <Text mb={2}>
                Name: {card?.userId.firstName} {card?.userId.lastName}
              </Text>
              <Text mb={2}>
                Email:{' '}
                <Link isExternal href={`mailto:${card?.userId.email}`}>
                  {card?.userId.email}
                </Link>
              </Text>
            </Box>
          </Box>
          <Divider mb={4} />
          <Box>
            <Heading fontSize='large'>Trade Information</Heading>
            <Box mt={4}>
              <Text mb={2}>
                Created:{' '}
                {new Date(card?.createdAt || Date.now()).toLocaleDateString()}
              </Text>
              <Text
                mb={2}
                color={
                  card?.approved
                    ? 'green'
                    : card?.declined
                    ? 'crimson'
                    : 'unset'
                }
              >
                Status:{' '}
                {card?.declined
                  ? 'Declined'
                  : card?.approved
                  ? 'Approved'
                  : 'Pending'}
              </Text>
            </Box>
            <Divider mb={2} />
            <Box>
              <Text mb={2}>Category: {card?.category}</Text>
              <Text mb={2}>Subcategory: {card?.subCategory}</Text>
            </Box>
            <Divider mb={2} />
            <Box>
              <Text mb={2}>
                Purchase amount: {currencyFormatter(card?.purchaseAmount || 0)}
              </Text>
              <Text mb={2}>Rate: {currencyFormatter(card?.rate || 0)}</Text>
              <Text mb={2}>Value: {currencyFormatter(card?.value || 0)}</Text>
            </Box>
          </Box>
          <Divider mb={4} />
          <Box>
            {card?.comments ? (
              <Box>
                <Heading fontSize='large'>Comments</Heading>
                <Text whiteSpace='pre-wrap' mt={4}>
                  {card.comments}
                </Text>
              </Box>
            ) : null}
          </Box>
          <Box>
            {card?.images.length ? (
              <Box>
                <Heading fontSize='large'>Images</Heading>
                <Flex css={{gap: 10}} flexWrap='wrap' mt={4}>
                  {card.images.map((image) => (
                    <Image
                      key={Math.random()}
                      src={image}
                      alt={card.category}
                      w={40}
                      objectFit='cover'
                      mb={2}
                    />
                  ))}
                </Flex>
              </Box>
            ) : null}
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme='blue' mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
