import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  IconButton,
  Icon,
  InputGroup,
  Textarea,
  Box,
  Text,
  Flex,
  useToast,
  Heading,
  Divider,
} from '@chakra-ui/react';
import { useState, useRef } from 'react';
import { IoAdd } from 'react-icons/io5';
import { useMutation } from 'react-query';
import { api } from '../../../services/api';
import { API_ROUTES } from '../../../services/routes';
import { useShowErrorNotification } from '../../../utils/hooks/index.hooks';
import { uploadImagesToCloudinary } from '../../../utils/index.helpers';

export function AddGiftCardModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => any;
}) {
  const [subcategories, setSubcategories] = useState<any[]>([]);
  const [uploading, setUploading] = useState(false);
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [subCategoryRate, setSubCategoryRate] = useState('');
  const [subCategoryMinimumAmount, setSubCategoryMinimumAmount] = useState('');
  const [file, setFile] = useState<any>('');
  const [terms, setTerms] = useState('');
  const { handleError } = useShowErrorNotification();
  const toast = useToast();
  const formRef = useRef(document.createElement('form'));

  const handleSubmit = async () => {
    try {
      setUploading(true);
      const [src] = await uploadImagesToCloudinary(file);
      setUploading(false);
      mutate(src);
    } catch (error) {
      handleError(error);
    }
  };

  const { mutate, isLoading } = useMutation(
    (image) =>
      api.post(API_ROUTES.CREATE_GIFT_CARD, {
        image,
        ...(subcategories.length && { subcategories }),
        category,
      }),
    {
      onError(err: any) {
        handleError(err);
      },
      onSuccess({ data: { message } }) {
        toast({
          title: 'Successful.',
          status: 'success',
          duration: 6000,
          isClosable: true,
          position: 'top-right',
          description: message,
        });
        formRef.current.reset();
        setFile(null);
        setTerms('');
        setCategory('');
        setSubCategory('');
        setSubCategoryRate('');
        setSubcategories([]);
        setSubCategoryMinimumAmount('');
      },
    }
  );

  const addSubcategory = () => {
    if (!subCategory || !subCategoryRate || !terms) {
      return;
    }

    const newArray = [
      ...subcategories,
      {
        name: subCategory,
        rate: subCategoryRate,
        minimum_amount: subCategoryMinimumAmount,
        terms,
      },
    ];
    setSubcategories(newArray);
    setSubCategory('');
    setSubCategoryRate('');
    setTerms('');
    setSubCategoryMinimumAmount('');
  };

  const removeSubcategory = (index: number) => {
    const newArray = subcategories.filter((_, i) => i !== index);
    setSubcategories(newArray);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Gift Card</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            id='create-card'
            ref={formRef}
          >
            <FormControl mb={4} isRequired>
              <FormLabel>Category</FormLabel>
              <Input
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              />
            </FormControl>
            <FormControl mb={6}>
              <FormLabel>Subcategories</FormLabel>
              <InputGroup>
                <Input
                  placeholder='Enter subcategory'
                  value={subCategory}
                  onChange={(e) => setSubCategory(e.target.value)}
                  mr={2}
                />
                <Input
                  value={subCategoryRate}
                  onChange={(e) => setSubCategoryRate(e.target.value)}
                  type='number'
                  placeholder='Enter rate'
                  mr={2}
                />
                <Input
                  value={subCategoryMinimumAmount}
                  onChange={(e) => setSubCategoryMinimumAmount(e.target.value)}
                  type='number'
                  placeholder='Enter minimum amount'
                />
                <IconButton
                  ml={2}
                  aria-label='Add subcategory'
                  icon={<Icon as={IoAdd} />}
                  colorScheme='teal'
                  onClick={addSubcategory}
                />
              </InputGroup>
              <Textarea
                value={terms}
                onChange={(e) => setTerms(e.target.value)}
                placeholder='Enter subcategory terms'
                my={4}
              />
            </FormControl>
            {subcategories.length ? (
              <Box mt={4}>
                <Divider />
                <Heading mb={4} mt={2} fontSize='md'>
                  Selected categories
                </Heading>
                <Flex w='100%' flexWrap='wrap'>
                  {subcategories.map(({ name, rate, terms }, i) => (
                    <Flex
                      w='100%'
                      justifyContent='space-between'
                      key={Math.random()}
                      flexWrap='wrap'
                      padding={2}
                      background='gray.200'
                      borderRadius={5}
                      mb={2}
                    >
                      <Text flexBasis='30%' isTruncated>
                        {name}
                      </Text>
                      <Text flexBasis='15%'>
                        {Number(rate).toLocaleString('en-NG', {
                          style: 'currency',
                          currency: 'NGN',
                        })}
                      </Text>
                      <Text flexBasis='40%' whiteSpace='pre-wrap'>
                        {terms}
                      </Text>
                      <Button
                        onClick={() => removeSubcategory(i)}
                        size='xs'
                        colorScheme='red'
                        my={2}
                      >
                        Remove
                      </Button>
                    </Flex>
                  ))}
                </Flex>
                <Divider my={4} />
              </Box>
            ) : null}
            <FormControl mb={4} isRequired>
              <FormLabel>Image</FormLabel>
              <Input
                type='file'
                onChange={(e: any) => setFile(e.target.files)}
                accept='image/*'
              />
            </FormControl>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button
            disabled={isLoading || uploading}
            loadingText={uploading ? 'Uploading image' : 'Creating gift card.'}
            colorScheme='blue'
            mr={3}
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            disabled={isLoading || uploading}
            isLoading={isLoading || uploading}
            type='submit'
            form='create-card'
          >
            Create card
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
