import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Image,
  Flex,
} from '@chakra-ui/react';

type Props = {
  isOpen: boolean;
  images: string[];
  onClose: () => any;
};

export function ViewImages({ isOpen, onClose, images }: Props) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Proof of payment</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex flexWrap='wrap'>
            {images.map((image) => (
              <Image
                key={Math.random()}
                src={image}
                objectFit='contain'
                alt='gift card image'
                height={100}
                m={4}
              />
            ))}
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme='blue' onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
