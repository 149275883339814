import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { AUTH_ROUTES } from './routes';
import { Dashboard } from '../../pages/home/index';
import { NavBar, NavButtons } from '../../utils/components/NavBar';
import { ManageCards } from '../../pages/cards';
import { Statistics } from '../../pages/stats';
import { Users } from '../../pages/users';
import { Flex, Button, Box, Heading } from '@chakra-ui/react';
import { User } from '../../pages/users/components/User';
import { useQuery } from 'react-query';
import { useShowErrorNotification } from '../../utils/hooks/index.hooks';
import { useState } from 'react';
import { StaffHomePage } from '../../pages/staff-home';
import { PageLoading } from '../../utils/components/loading/PageLoading';
import { api } from '../../services/api';
import { API_ROUTES } from '../../services/routes';
import { LogoutAlert } from '../../pages/home/components/LogoutAlert';

const { HOME, CARDS, STATS, USERS, USER, STAFF_HOME } = AUTH_ROUTES;

function AdminApp() {
  return (
    <BrowserRouter>
      <Box position='relative' my={4} p={4}>
        <Box
          w='20%'
          bg='blue.900'
          top={0}
          left={0}
          position='fixed'
          display={['none', 'none', 'block']}
          h='100vh'
        >
          <Flex flexWrap='wrap' flexDir='column' alignSelf='center'>
            <NavButtons />
          </Flex>
        </Box>
        <Flex justify='flex-end'>
          <NavBar />
        </Flex>
        <Box position='relative' width='80%' left={[null, null, '20%']}>
          <Switch>
            <Route path={HOME} exact component={Dashboard} />
            <Route path={CARDS} exact component={ManageCards} />
            <Route path={STATS} exact component={Statistics} />
            <Route path={USERS} exact component={Users} />
            <Route path={USER} exact component={User} />
            <Redirect path='/*' to={HOME} />
          </Switch>
        </Box>
      </Box>
    </BrowserRouter>
  );
}

function StaffApp() {
  const [showLogoutAlert, setShowLogoutAlert] = useState(false);
  return (
    <BrowserRouter>
      <Box p={10}>
        <Flex
          mt={4}
          mb={8}
          px={[null, null, 10]}
          width='100%'
          justify='space-between'
        >
          <Heading fontSize='2xl'>Gift Cards</Heading>
          <Button
            colorScheme='red'
            variant='outline'
            onClick={() => setShowLogoutAlert(true)}
          >
            Log out
          </Button>
          <LogoutAlert
            isOpen={showLogoutAlert}
            onClose={() => setShowLogoutAlert(false)}
          />
        </Flex>
        <Switch>
          <Route path={STAFF_HOME} exact component={StaffHomePage} />
          <Redirect path='/*' to={STAFF_HOME} />
        </Switch>
      </Box>
    </BrowserRouter>
  );
}

export function Admin() {
  const [isStaff, setIsStaff] = useState(false);
  const { handleError } = useShowErrorNotification();
  const { isLoading } = useQuery(
    'get admin data',
    () => api.get(API_ROUTES.ADMIN_ME),
    {
      onError(err) {
        handleError(err);
      },
      onSuccess({ data: { data } }) {
        setIsStaff(data.isStaff);
      },
    }
  );

  if (isLoading) {
    return <PageLoading />;
  }

  if (isStaff) {
    return <StaffApp />;
  }

  return <AdminApp />;
}
