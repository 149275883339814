import {
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatGroup,
  Flex,
  StatArrow,
  Box,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { api } from '../../services/api';
import { API_ROUTES } from '../../services/routes';
import { PageLoading } from '../../utils/components/loading/PageLoading';
import { PageHeading } from '../../utils/components/PageHeading';
import { useShowErrorNotification } from '../../utils/hooks/index.hooks';
import { currencyFormatter } from '../../utils/index.helpers';

export function Statistics() {
  const [stats, setStats] = useState({
    totalWithdrawn: 0,
    totalWithdrawable: 0,
    totalRegistered: 0,
    totalActive: 0,
    returningUsers: 0,
    weeklyBTCTradeVolume: 0,
    dailyBTCTradeVolume: 0,
    monthlyBTCTradeVolume: 0,
    weeklyGiftCardTradeVolume: 0,
    dailyGiftCardTradeVolume: 0,
    monthlyGiftCardTradeVolume: 0,
    dailyBTCTradeAmount: 0,
    weeklyBTCTradeAmount: 0,
    monthlyBTCTradeAmount: 0,
    dailyGiftCardTradeAmount: 0,
    weeklyGiftCardTradeAmount: 0,
    monthlyGiftCardTradeAmount: 0,
  });
  const { handleError } = useShowErrorNotification();
  const { isLoading } = useQuery(
    'stats query',
    () => api.get(API_ROUTES.GET_STATS),
    {
      onError(e) {
        handleError(e);
      },
      onSuccess({ data }) {
        setStats(data.data);
      },
    }
  );

  if (isLoading) {
    return <PageLoading />;
  }

  return (
    <Box m={4}>
      <PageHeading heading='Overview' />
      <Flex flexWrap='wrap' css={{ gap: 10 }}>
        <StatGroup
          display='flex'
          flexDir={['column', 'column', 'row']}
          justify={['center', 'center', null]}
          flexWrap='wrap'
          bg='gray.200'
          p={4}
          borderRadius={8}
          mb={4}
          minW={[300, 300, 400]}
        >
          <Stat mb={[4, 4, null]}>
            <StatLabel>Total Withdrawn</StatLabel>
            <StatNumber>{currencyFormatter(stats.totalWithdrawn)}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Total Withdrawable</StatLabel>
            <StatNumber>
              {currencyFormatter(stats.totalWithdrawable)}
            </StatNumber>
          </Stat>
        </StatGroup>
        <StatGroup
          display='flex'
          flexDir={['column', 'column', 'row']}
          justify={['center', 'center', null]}
          flexWrap='wrap'
          bg='gray.200'
          p={4}
          borderRadius={8}
          mb={4}
          minW={[300, 300, 400]}
        >
          <Stat mb={[4, 4, null]}>
            <StatLabel>Total Registered</StatLabel>
            <StatNumber>
              {stats.totalRegistered.toLocaleString('en-NG')}
            </StatNumber>
          </Stat>
          <Stat mb={[4, 4, null]}>
            <StatLabel>Total Active</StatLabel>
            <StatNumber>{stats.totalActive.toLocaleString('en-NG')}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Returning Users</StatLabel>
            <StatNumber>
              {stats.returningUsers.toLocaleString('en-NG')}
            </StatNumber>
          </Stat>
        </StatGroup>
        <StatGroup
          display='flex'
          flexDir={['column', 'column', 'row']}
          justify={['center', 'center', null]}
          flexWrap='wrap'
          bg='gray.200'
          p={4}
          borderRadius={8}
          mb={4}
          minW={[300, 300, 400]}
        >
          <Stat mb={[4, 4, null]}>
            <StatLabel>Daily BTC</StatLabel>
            <StatNumber>
              {stats.dailyBTCTradeVolume.toLocaleString('en-NG')}
            </StatNumber>
            <StatHelpText>
              <StatArrow type='increase' />
              {currencyFormatter(stats.dailyBTCTradeAmount)}
            </StatHelpText>
          </Stat>
          <Stat nb={[4, 4, null]}>
            <StatLabel>Weekly BTC</StatLabel>
            <StatNumber>
              {stats.weeklyBTCTradeVolume.toLocaleString('en-NG')}
            </StatNumber>
            <StatHelpText>
              <StatArrow type='increase' />
              {currencyFormatter(stats.weeklyBTCTradeAmount)}
            </StatHelpText>
          </Stat>
          <Stat>
            <StatLabel>Monthly BTC</StatLabel>
            <StatNumber>
              {stats.monthlyBTCTradeVolume.toLocaleString('en-NG')}
            </StatNumber>
            <StatHelpText>
              <StatArrow type='increase' />
              {currencyFormatter(stats.monthlyBTCTradeAmount)}
            </StatHelpText>
          </Stat>
        </StatGroup>
        <StatGroup
          display='flex'
          flexDir={['column', 'column', 'row']}
          justify={['center', 'center', null]}
          flexWrap='wrap'
          bg='gray.200'
          p={4}
          borderRadius={8}
          mb={4}
          minW={[300, 300, 400]}
        >
          <Stat mb={[4, 4, null]}>
            <StatLabel>Daily Cards</StatLabel>
            <StatNumber>
              {stats.dailyGiftCardTradeVolume.toLocaleString('en-NG')}
            </StatNumber>
            <StatHelpText>
              <StatArrow type='increase' />
              {currencyFormatter(stats.dailyGiftCardTradeAmount)}
            </StatHelpText>
          </Stat>
          <Stat mb={[4, 4, null]}>
            <StatLabel>Weekly Cards</StatLabel>
            <StatNumber>
              {stats.weeklyGiftCardTradeVolume.toLocaleString('en-NG')}
            </StatNumber>
            <StatHelpText>
              <StatArrow type='increase' />
              {currencyFormatter(stats.weeklyGiftCardTradeAmount)}
            </StatHelpText>
          </Stat>
          <Stat>
            <StatLabel>Monthly Cards</StatLabel>
            <StatNumber>
              {stats.monthlyGiftCardTradeVolume.toLocaleString('en-NG')}
            </StatNumber>
            <StatHelpText>
              <StatArrow type='increase' />
              {currencyFormatter(stats.monthlyGiftCardTradeAmount)}
            </StatHelpText>
          </Stat>
        </StatGroup>
      </Flex>
    </Box>
  );
}
